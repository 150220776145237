module.exports = [{
  plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
  options: {
    "plugins": [],
    "maxWidth": 590,
    "linkImagesToOriginal": false
  }
}, {
  plugin: require('/opt/build/repo/node_modules/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('/opt/build/repo/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
  options: {
    "plugins": [],
    "extensions": [".mdx", ".md"],
    "gatsbyRemarkPlugins": [{
      "resolve": "gatsby-remark-images",
      "options": {
        "maxWidth": 820,
        "quality": 90,
        "linkImagesToOriginal": false
      }
    }, {
      "resolve": "gatsby-remark-external-links",
      "options": {
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }
    }, {
      "resolve": "gatsby-remark-responsive-iframe",
      "options": {}
    }]
  }
}, {
  plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
  options: {
    "plugins": [],
    "trackingId": "UA-144040266-1"
  }
}, {
  plugin: require('/opt/build/repo/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}];